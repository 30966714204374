import logo from "./images/Logo.webp";
import "./styles/navFooter.scss";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();


  return (
    <nav>
      <img src={logo} alt='pmw logo' onClick={() => navigate('/')} />
      <section className='button__area'>
            <button
              className="enrol_button"
              onClick={() => navigate("/register")}
            >
              Apply Now
            </button>
      </section>

    </nav>
  );
}
