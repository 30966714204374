import '../styles/timeline.scss';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SchoolIcon from '@mui/icons-material/School';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { useNavigate } from 'react-router-dom';

export default function TimeLine() {
  const navigate = useNavigate()

  return (
    <section className="join">
      <h2>How to join the PMW business academy</h2>
      <hr />
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{backgroundColor: '#eaaf59'}}>
              <CheckIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" className='time__header'>
              Begin Applying
            </Typography>
            <Typography sx={{ width: "80%" }} className='time__text'>Click "Apply Now" to start your journey. Complete the free application form with details about yourself and your business. Remember to be thorough—more detail is better.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{backgroundColor: '#eaaf59'}}>
              <PermPhoneMsgIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" className='time__header'>
              Schedule Your Consultation
            </Typography>
            <Typography sx={{ width: "80%", margin: "0 0 0 auto", }} className='time__text'>Book a call with our team to explore the PMW Business Academy. Ask questions and determine if the programme is right for you. No immediate commitments required.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{backgroundColor: '#eaaf59'}} >
              <AddCircleOutlineIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" className='time__header'>
              Enrolment Decision
            </Typography>
            <Typography sx={{ width: "80%" }} className='time__text'>We'll review your application and our call to see if our Business Academy is a good fit for you. If accepted, we’ll welcome you to start your onboarding process.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{backgroundColor: '#eaaf59'}}>
              <SchoolIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" className='time__header'>
              Welcome To The Academy
            </Typography>
            <Typography sx={{ width: "80%", margin: "0 0 0 auto", }} className='time__text'>You’ll get instant access to online training, details about events, and a welcome pack with support materials. We’ll assist with your initial steps for quick wins.</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot sx={{backgroundColor: '#eaaf59'}} >
              <InsertInvitationIcon />
            </TimelineDot>
            {/* <TimelineConnector /> */}
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span" className='time__header'>
              Experience Our Live, In-Person Quarterly Events
            </Typography>
            <Typography sx={{ width: "80%" }} className='time__text'>Join our immersive two-day quarterly events, designed to boost your personal and business growth. Benefit from ongoing support and guidance, dedicated to your success.</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>

      <button
        className="enrol_button"
        onClick={() => navigate("/register")}
      >
        Apply Now
      </button>
    </section>



  )
}