import { useState } from "react";
import { InputLabel, MenuItem, Select, Alert, Checkbox, FormControlLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import '../styles/register.scss'
import axios from "axios";
import { useNavigate } from "react-router-dom";


export default function Register() {
  const [first, setFirst] = useState(true);
  const [name, setName] = useState('');
  const [last, setLast] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [town, setTown] = useState('');
  const [postcode, setPostcode] = useState('');
  const [business, setBusiness] = useState('');
  const [occupation, setOccupation] = useState('');
  const [level, setLevel] = useState('');
  const [why, setWhy] = useState('');
  const [barrier, setBarrier] = useState('');
  const [ideal, setIdeal] = useState('');
  const [current, setCurrent] = useState('');
  const [socials, setSocials] = useState('');
  const [turnover, setTurnover] = useState('');
  const [consent, setConsent] = useState(false);
  const [acknowledge, setAcknowledge] = useState(false);
  const url = 'https://dev.pmwwebdesign.com';
  // const url = 'http://localhost:3999';
  const navigate = useNavigate();

  // !ALERTS
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("");

  const handleNext = () => {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    const postcodeRegex = /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/
    if (name === "" || last === "" || email === "" || phone === "" || street === "" || town === "" || postcode === "" || business === "" || occupation === "" || level === "") {
      setMessage("All fields are required")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else if (!email.match(regex)) {
      setMessage("Email format is wrong")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else if (!postcode.match(postcodeRegex)) {
      setMessage("Postcode not valid")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      setFirst(false)
    }
  }

  const handleApply = () => {

    if (why === "" || barrier === "" || ideal === "" || current === "" || socials === "" || turnover === "" || !consent || !acknowledge) {
      setMessage("All fields are required")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else if (!consent) {
      setMessage("Please consent to data usage")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else if (!acknowledge) {
      setMessage("Please acknowledge refresh if leaving the page")
      setSeverity("error")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      let data = {
        name: name,
        lastName: last,
        email: email,
        phone: phone,
        address: street,
        town: town,
        postcode: postcode,
        businessName: business,
        occupation: occupation,
        businessExperience: level,
        reason: why,
        future: ideal,
        current: current,
        blocks: barrier,
        socials: socials,
        turnover: turnover,
        consent: consent,
      }

      console.log(data)
      axios.post(`${url}/biz-academy`, data)
        .then((res) => {
          navigate("/thank-you?form")
        }).catch((err) => {
          console.log(err)
          setMessage("Something went wrong, please try again")
          setSeverity("warning")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)
        })
    }
  }



  return (
    <main className="register">
      <h2>PMW Business Academy Application Form</h2>
      <p>Please ensure you provide as much information as possible on your application form.
        Completing the form will allow us to learn more about you and schedule a call.
        Be thorough in your responses, more detail is always better.</p>
      <section className="form">
        {first ? (
          <>
            <section className="form__row">
              <TextField
                required
                label="First Name"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setName(e.target.value)}
              />

              <TextField
                required
                label="Last Name"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setLast(e.target.value)}

              />
            </section>
            <section className="form__row">
              <TextField
                required
                label="Email address"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                required
                label="Phone number"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setPhone(e.target.value)}
                pattern='[0-9]*'
              />
            </section>
            <section className="form__row">
            <TextField
                required
                label="Name Of Business"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setBusiness(e.target.value)}

              />
              <TextField
                required
                label="Current Occupation"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setOccupation(e.target.value)}

              />
            </section>
            <section className="form__row">
              <TextField
                required
                label="Street Address"
                className="text--field"
                sx={{ m: 1, width: '100%' }}
                onChange={(e) => setStreet(e.target.value)}
              />
            </section>
            <section className="form__row">
              <TextField
                required
                label="Town"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setTown(e.target.value)}

              />

              <TextField
                required
                label="Postcode"
                className="text--field"
                sx={{ m: 1, width: '45%' }}
                onChange={(e) => setPostcode(e.target.value)}

              />
            </section>
            <section className="form__column">
              <InputLabel className="select--label" >How Many Years Business Experience Do You Have</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                className="column--select"
                label="What Level Of Property Investment Experience Do you Have?"
                required
                sx={{ width: "100%" }}
                onChange={(e) => setLevel(e.target.value)}
              >
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="1-5 years experience">1-5 years experience</MenuItem>
                <MenuItem value="6+ years experience">6+ years experience</MenuItem>
              </Select>
            </section>
          </>
        ) : (
          <>
            <section className="form__row">
              <TextField
                className="form__row--multiline"
                label="Tell us why you are here today?"
                multiline
                sx={{ m: 1, width: '100%' }}
                rows={4}
                onChange={(e) => setWhy(e.target.value)}
              />
            </section>
            <section className="form__row">
            <TextField
                className="form__row--multiline"
                label="What are your biggest business challenges currently?"
                multiline
                sx={{ m: 1, width: '100%' }}
                rows={4}
                onChange={(e) => setBarrier(e.target.value)}
              />
            </section>
            <section className="form__row">
              <TextField
                className="form__row--multiline"
                label="In an ideal world what would you like to achieve in your business in the next 12 months?"
                multiline
                sx={{ m: 1, width: '100%' }}
                rows={4}
                onChange={(e) => setIdeal(e.target.value)}
              />
            </section>
            <section className="form__row">
              <TextField
                className="form__row--multiline"
                label="Tell me a bit more about your current business and what you do"
                multiline
                sx={{ m: 1, width: '100%' }}
                rows={4}
                onChange={(e) => setCurrent(e.target.value)}
              />
            </section>
            <section className="form__row">
              <TextField
                className="form__row--multiline"
                label="Add your business website and social handles here, including your own personal social profile"
                multiline
                sx={{ m: 1, width: '100%' }}
                rows={4}
                onChange={(e) => setSocials(e.target.value)}
              />
            </section>
            <section className="form__column">
              <InputLabel >Current Business Turnover</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Current Business Turnover"
                required
                sx={{ width: "100%" }}
                onChange={(e) => setTurnover(e.target.value)}
              >
                <MenuItem value="£0 - £50k">£0 - £50k</MenuItem>
                <MenuItem value="£50k - £100k">£50k - £100k</MenuItem>
                <MenuItem value="£100k - £500k">£100k - £500k</MenuItem>
                <MenuItem value="£500k - £1 Million">£500k - £1 Million</MenuItem>
                <MenuItem value="£1M - £3M">£1M - £3M</MenuItem>
                <MenuItem value="£3M - £5M">£3M - £5M</MenuItem>
                <MenuItem value="£5M - £10M">£5M - £10M</MenuItem>
                <MenuItem value="£10M+">£10M+</MenuItem>

              </Select>
            </section>

            <section className="form__row" style={{marginTop: '2%'}}>
              <FormControlLabel required control={<Checkbox sx={{ alignSelf: "flex-start", marginTop: "-0.5%" }} onChange={(e) => setConsent(e.target.checked)} />} label="I agree that my data may be used for any information related to the products and services offered by Paul McFadden Wealth. I can easily revoke this by simply writing to mail@teampmw.com" className="check--box" />
            </section>
            <section className="form__row">
              <FormControlLabel required control={<Checkbox onChange={(e) => setAcknowledge(e.target.checked)} />} label="I understand that this page will reload and I will have to book in a time to complete my application" className="check--box" />
            </section>

          </>
        )}
      </section>
      {showAlert ? (
        <Alert severity={severity}>{message}</Alert>
      ) : (
        <>
          {first ? (
            <button className="app__button" onClick={handleNext}>Next</button>
          ) : (
            <button className="app__button" onClick={handleApply}>Apply</button>
          )}
        </>
      )}
    </main>
  )
}