import './styles/navFooter.scss';
import logo from './images/Logo.webp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { AiFillTikTok, AiFillInstagram } from "react-icons/ai";
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cpd from './images/cpd.webp';

export default function Footer() {
  const [year, setYear] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const now = new Date();
    let year = now.getFullYear()
    setYear(year)
  }, [])

  return (
    <footer>
      <section className="social__bar">
        <h6>Connect with us</h6>
        <section className="socials">
          <a href="https://www.facebook.com/paulmcfaddenwealth" target="_blank" rel="noferrer"><FacebookIcon className="social--icon" /></a>
          <a href="https://www.instagram.com/paulmcfaddenwealth/" target="_blank" rel="noferrer"> <AiFillInstagram className="social--icon" /></a>
          <a href="https://www.linkedin.com/in/paulmcfaddenwealth/" target="_blank" rel="noferrer"><LinkedInIcon className="social--icon" /></a>
          <a href="https://www.youtube.com/channel/UCDbc4FlWO3Xd0rrOQ7IXV6g" target="_blank" rel="noferrer"><YouTubeIcon className="social--icon" /></a>
          <a href="https://www.tiktok.com/@paulmcfaddenwealth?lang=en" target="_blank" rel="noferrer"><AiFillTikTok className="social--icon" /></a>
        </section>
      </section>
      <section className='logo__bar'>
        <a href='https://drive.google.com/file/d/1iAgy6JSPFHIjuT0iV_958cxRLog4eeJ-/view?usp=sharing' target="_blank" rel="noferrer">T&C's</a>
        <h6 onClick={() => navigate('/privacy-policy')}>Privacy Policy</h6>
        <img src={logo} alt="pmw logo" />
        <h6>COPYRIGHT {year} <CopyrightIcon /> ALL RIGHTS RESERVED</h6>
      </section>
    </footer>
  )
}