import Carousel from 'react-grid-carousel'
import '../styles/carousel.scss';
import RM from '../images/RM.webp';
import DS from '../images/DS.webp';
import OC from '../images/OC.webp';
import LA from '../images/LA.webp';
import BW from '../images/BW.webp';
import RW from '../images/RW.webp';
import IB from '../images/IB.webp';
import IG from '../images/IG.webp';
import BM from '../images/BM.webp';

export default function Testimonials() {

  return (
    <section className='carousel__main' >
      <h2>What Our Clients Are Saying</h2>
      <Carousel cols={3} rows={1} gap={10} loop={true} hideArrow={false} 
      responsiveLayout={[
        {
          breakpoint: 750,
          cols: 1,
          loop: true,
          autoplay: 5000,
          hideArrow: false,
          },
          {
            breakpoint: 950,
            cols: 1,
            hideArrow: false,
          }
        ]}>
        <Carousel.Item >
          <img width="20%" src={RM} className='carousel--image' alt="testimonial image" />
          <h4>Rob Moore</h4>
          <p className="carousel--text">“As a mentor to Entrepreneurs 0 to £20m & founder of 2 business training companies, working with Paul in training & speaking at events, he's been prolific, grounded & is a great success not just in Scotland but across the whole of the UK. Paul is truly a (young) "Disruptive Entrepreneur" & I look forward to more collaborations & more success for Paul in property & business.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={DS} className='carousel--image' alt="testimonial image" />
          <h4>Dylan Stewart</h4>
          <p className="carousel--text">“I have known Paul for a long time through many business ventures and I am delighted to be doing business with him to this day. When it comes to property investments Paul knows his stuff. He has helped me structure deals in a way that have really helped me on my property journey. Paul is a man you want to be around if you want to go far, his passion and drive for success in business and in life is contagious and very inspiring.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={OC} className='carousel--image' alt="testimonial image" />
          <h4>Olivia Conlon</h4>
          <p className="carousel--text">“Having invested in property before, we thought we knew a lot of the strategies that were possible, but Paul really opened our eyes to strategies we had never heard of before, it really is great to be mentored by someone who has already walked the path! I cannot recommend Protege enough to those who are looking to get into property or for those who already are, Protege really is a class event.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={LA} className='carousel--image' alt="testimonial image" />
          <h4>Leslie Asante</h4>
          <p className="carousel--text">“I've worked with Paul for a while now and when it comes to business, and in particular property related business, Paul's knowledge, experience and advice is second to none.
            Whatever stage you are at in your property journey, if you are looking to accelerate your business growth,
            Paul is the man you must consult - he will show you the path of least resistence.
            I highly recommended Paul.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={BW} className='carousel--image' alt="testimonial image" />
          <h4>Barry Wilson</h4>
          <p className="carousel--text">“Wow-what knowledge has this man got !!! Thoroughly enjoyed learning from him over the last few days. Paul is the very best in his field and someone I truly look up too. He has a no-nonsense style that delivers so well and I can resonate with. Anyone thinking of doing his course, just do it and see yourself grow.”</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={RW} className='carousel--image' alt="testimonial image" />
          <h4>Robert Wells</h4>
          <p className="carousel--text">"Paul delivers all his content with huge amounts of energy and enthusiasm which all comes from a place of absolute knowledge and experience within an industry that he has mastered."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={IB} className='carousel--image' alt="testimonial image" />
          <h4>Ian Brierley</h4>
          <p className="carousel--text">"From Knowing and working with Paul, I can comprehensively say he is one of those infectious people who always wants to deliver value to you. He does this through his expert knowledge, and determination to help others."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={IG} className='carousel--image' alt="testimonial image" />
          <h4>Ian Green</h4>
          <p className="carousel--text">"Paul is extremely knowledgeable when it comes to property and business. He is someone that has helped me immensely, always giving an honest opinion but a clear perspective on ideas based on his experience from the many deals he has done. He has clear focus and drive whilst helping people to succeed. He is able to assist in many deals with his expertise and large network. A great person to work with."</p>
        </Carousel.Item>
        <Carousel.Item >
          <img width="20%" src={BM} className='carousel--image' alt="testimonial image" />
          <h4>Barry McEwan</h4>
          <p className="carousel--text">"I have known Paul as a friend and business partner for 7 years and would have no hesitation in recommending him. He is one of the most driven entrepreneurs I have known and with his honest and open personality he can assist you on your property journey."</p>
        </Carousel.Item>
        {/* ... */}
      </Carousel>
    </section>
  );
}