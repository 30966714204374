import '../styles/thankyou.scss';
import { useState, useEffect } from 'react';
import { InlineWidget } from "react-calendly";


export default function ThankYou() {
  const [fromForm, setFromFrom] = useState(false);
  const [date, setDate] = useState('')


  const calendarStyles = {
    minWidth: '80%',
    height: '80vh',
    margin: '0 auto',
  }

  useEffect(() => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    let finalDate = `${year}-${month}`

    setDate(finalDate)

    let params = new URLSearchParams(window.location.search)

    if (params.has('form')) {
      setFromFrom(true)
    }
  }, [date])




  return (
    <main className="thank__you">
      <h2>Thank you for applying to join PMW Business Academy</h2>

      {fromForm ? (
        <>
          <h3>Please book a meeting with the team</h3>
          <InlineWidget url={`https://calendly.com/d/cmjc-tsw-4rq/business-academy?month=${date}`}
            styles={calendarStyles}
          />
        </>
      ) : null}
    </main>
  )
}