import "./styles/home.scss";
import ReactPlayer from "react-player";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import Testimonials from "./pages/Testimonials";
import { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, Stack, FormGroup, FormControlLabel, Switch } from "@mui/material";
import strategy from './images/strategy.webp';
import event from './images/event.webp';
import telegram from './images/support_group.webp';
import online from './images/ZoomCall.webp';
import paul from './images/paul_bio.webp';
import retreat from './images/retreat.webp';
import TimeLine from "./pages/Timeline";
import thumb from './images/thumb.webp'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  '@media(max-width: 700px)': {
    width: '90vw',
    maxHeight: '80vh',
  }

};

export default function Home() {
  const navigate = useNavigate();
  const [showNonEssential, setShowNonEssential] = useState(false)

  // COOKIES
  const [open, setOpen] = useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    let cookies = localStorage.getItem('pmw_cookies')
    if (cookies) {
      handleClose()
    }
  }, []);

  const handleCookies = () => {
    let pmw_cooks = true

    localStorage.setItem('pmw_cookies', pmw_cooks)
    handleClose();
  }


  const handleCustomise = () => {
    setShowNonEssential(true)
  }

  function gtag() { window.dataLayer.push(arguments); }


  const handleConsent = (cookie, value) => {
    if (value == false) {
      gtag('consent', 'update', {
        [cookie]: 'denied'
      })
    } else {
      gtag('consent', 'update', {
        [cookie]: 'granted'
      })
    }
  }

  const handleDecline = async () => {
    await gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    });

    handleClose()
  }

  return (
    <main className="home__main">
      <section className="hero">
        <h1>PMW Business Academy</h1>
        <p>Commit yourself to levelling up, getting results, and guaranteeing your success</p>
        <div className='player--wrapper'>
          <ReactPlayer
            url="https://vimeo.com/998608532?share=copy"
            controls
            style={{ aspecRatio: "16/9" }}
            className="video--player"
            light={thumb}
            width='100%'
            height='100%'

          />
        </div>
        <button
          className="enrol_button"
          onClick={() => navigate("/register")}
        >
          Apply Now
        </button>
      </section>
      {/* WHAT IS COVERED */}
      <section className="includedSection">
        <h2>What You're Going To Learn</h2>
        <hr />

        <h4>At the PMW Business Academy, you’ll gain the skills and knowledge essential for achieving long-term success.</h4>
        <h4>This comprehensive programme is designed to empower you with practical insights and strategies that you can apply immediately.</h4>
        <h4>Here’s a glimpse of what you’ll learn:</h4>

        <section className="included">
          <section className="column">
            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>In-Depth Business Foundations</h6>
              </aside>
              <p>
                Build a solid foundation by mastering essential business pillars, ensuring your operations are well-positioned for long-term growth, sustainability, and enduring success.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Life-Long Business Skills</h6>
              </aside>
              <p>
                Gain crucial skills for effective business management, including strategic planning and time management, to support your journey and enhance your overall business operations.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Winning Mindset Development</h6>
              </aside>
              <p>
                Overcome challenges and build confidence by addressing fear, procrastination, and self-doubt. Develop the ability to make decisive actions with ease and enhance your resilience.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Strategic Planning &amp; Clarity</h6>
              </aside>
              <p>
                Develop strong, long-term client relationships from the start, boosting satisfaction and loyalty, and setting a solid foundation for your business’s future success and growth.
              </p>
            </section>

            <section className="sectioncontent left">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Work-Life Balance &amp; Time Management</h6>
              </aside>
              <p>
                Discover how to effectively balance your business with your lifestyle through smart systems, enabling you to increase income while avoiding endless hours of work and burnout.
              </p>
            </section>
          </section>

          <section className="column">
            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Sales &amp; Marketing Mastery</h6>
              </aside>
              <p>
                Overcome sales fears to confidently sell your products and boost revenue. Master digital marketing, from lead gen to social media, to build a brand that attracts and converts.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Automation &amp; Efficiency</h6>
              </aside>
              <p>
                Learn to reduce stress and workload by leveraging automation tools, which will improve your efficiency in delivering products and services, allowing you to focus on growth.
              </p>
            </section>


            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Client Satisfaction &amp; Retention</h6>
              </aside>
              <p>
                Develop strong, long-term client relationships from the start, boosting satisfaction and loyalty, and setting a solid foundation for your business’s future success and growth.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Expert Support &amp; Guidance</h6>
              </aside>
              <p>
                Receive comprehensive support from our expert coaches, who are committed to guiding you through every stage of your journey, ensuring you achieve success and reach your goals.
              </p>
            </section>

            <section className="sectioncontent right">
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>Industry Authority</h6>
              </aside>
              <p>
                Stand out in your industry and establish yourself as the go-to expert, even if you’re starting from scratch. Build your reputation and attract opportunities from the first day.
              </p>
            </section>
          </section>
        </section>
      </section>
      <section className="skills">
        <h2>With these skills, you'll be fully equipped to grow your business,
          overcome obstacles, and achieve your business goals.</h2>
      </section>
      <section className="what__is">
        <h2>How You Will Get Results</h2>
        <hr />
        <p>At the PMW Business Academy, we drive your business success with our comprehensive 12-month programme. In-depth workshops, ongoing expert support, and strategic planning tailored to your needs.</p>
        <p>You’ll gain actionable strategies through quarterly sessions, complemented by monthly coaching and access to a diverse network of successful entrepreneurs. We focus on building robust business foundations, helping you transition from working in your business to working on it, and cultivating the mindset to overcome any challenge.</p>
        <p>With exclusive retreats and continuous guidance, you’ll be fully equipped to scale your business, achieve incredible results, and unlock your full potential.</p>
        <button
          className="enrol_button"
          onClick={() => navigate("/register")}
        >
          Apply Now
        </button>
      </section>

      {/* INCLUDED */}
      <section className="extras">
        <h2>What’s Included In The PMW Business Academy</h2>
        <hr />
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Live In-Person Events</h4>
            <p>Over the course of a year, you'll attend four immersive two-day events that accelerate your business growth. These in-person sessions offer a unique opportunity to delve into crucial business aspects, surrounded by like-minded entrepreneurs. Each event focuses on action, providing practical strategies you can implement immediately for tangible results. Plus, all events are recorded, allowing you to revisit the material whenever needed.</p>
          </aside>
          <img src={event} alt="In Person Event" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Detailed Strategy Plan</h4>
            <p>After each group coaching event, you’ll receive a detailed strategy plan tailored specifically to your business. These comprehensive plans are designed to keep you on track and ensure you make consistent progress toward your goals. With these roadmaps, you can accurately measure your results, adjust your strategies as needed, and achieve ongoing improvement and growth, providing clear guidance for your business journey and beyond.</p>
          </aside>
          <img src={strategy} alt="Private Community" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Online Coaching Sessions</h4>
            <p>You’ll also gain access to monthly online coaching sessions via Zoom. These sessions offer continuous support and accountability, helping you stay focused and motivated. Each session builds on the knowledge gained from the live events, allowing you to ask questions, get expert advice, and engage with other participants. All sessions are recorded, so you can review them at your convenience, ensuring you never miss valuable insights.</p>
          </aside>
          <img src={online} alt="Online Sessions" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>Private Telegram Support Group</h4>
            <p>Being part of our private Telegram group, giving you 24/7 access to a supportive community of peers and mentors. This group is an invaluable resource for collaboration, networking, and real-time problem-solving. Whether you’re facing specific challenges or exploring new opportunities, you'll get immediate assistance and advice, helping you navigate your business journey with confidence and support from experienced professionals.</p>
          </aside>
          <img src={telegram} alt="Private telegram group" />
        </section>
        <section className="extra__boxes">
          <aside className="text__side">
            <h4>PMW Retreat</h4>
            <p>As a member of the PMW Business Academy, you’re invited to our exclusive annual retreat. This event is more than just a getaway; it’s a chance to deepen your learning, and build stronger relationships with fellow entrepreneurs.. The retreat combines business development with relaxation and fun, ensuring you return with renewed energy, fresh insights, and practical strategies to apply directly to your business for continued success.</p>
          </aside>
          <img src={retreat} alt="PMW Retreat" />
        </section>


        <button
          className="enrol_button"
          onClick={() => navigate("/register")}
        >
          Apply Now
        </button>
      </section>

      {/* WHO IS IT FOR */}
      <section className="includedSection" style={{ paddingBottom: "4%" }}>
        <h2>Our PMW Business Academy Is For You If</h2>
        <hr></hr>
        <section className="included">
          <section className="column">
            <section className="sectioncontent left wiif" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>You're A Start-Up Business Owner In The Early Stages</h6>
              </aside>
              <p>
                Are you in the first few years of your business and eager to achieve consistent growth? If you’re
                looking to generate more leads, increase sales, and boost your revenue and profits, our academy
                provides the strategies and support you need to accelerate your success.
              </p>
            </section>

            <section className="sectioncontent left wiif" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>You’ve Been In Business But Are Struggling To Make Significant Progress</h6>
              </aside>
              <p>
                Has your business plateaued after years of operation? If you’re finding it difficult to scale and break
                through to the next level, our academy will help you overcome stagnation and unlock your
                business’s full potential.
              </p>
            </section>
          </section>

          <section className="column">
            <section className="sectioncontent right wiif" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>You're Still Working <strong>In</strong> Your Business Rather Than On It</h6>
              </aside>
              <p>
                Is your business still heavily dependent on your daily involvement to function? If you’re stuck in
                the operational grind and unable to focus on growth, we’ll show you how to streamline processes,
                delegate effectively, and shift from working in your business to working on it.
              </p>
            </section>

            <section className="sectioncontent right wiif" >
              <aside className="side">
                <TaskAltIcon className="online--icon" />
                <h6>
                  You're Stuck In A 9-5 & Want To Turn Your Idea Into A Full-Time Business
                </h6>
              </aside>
              <p>
                Do you feel under-appreciated and underpaid in your current job? If you’re dreaming of quitting
                your 9-5 to start your own business but don’t know where to begin, the PMW Business Academy
                will guide you through every step of turning your idea into a thriving profitable business.
              </p>
            </section>
          </section>
        </section>
      </section>

      <TimeLine />
      <section className="learn" >
        <h2>
          By the End of The 12 Months, You Will
        </h2>
        <hr />
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Mastered Business Foundations</h4>
            <p>Gained a thorough understanding of the six core pillars of business, providing you with essential knowledge to build a strong foundation that supports your sustainable growth and scalability.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Transitioned from Working IN Your Business to Working ON It</h4>
            <p>Developed skills and strategies to shift from daily operational tasks to strategic growth, enabling you to focus on long-term business development and thrive independently of constant involvement.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Create &amp; Implement Effective Strategic Plans</h4>
            <p>Learned to create detailed and actionable strategic plans that offer clarity and focus on your next steps, helping you successfully achieve your business goals and objectives with confidence.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Develop a Winning Mindset</h4>
            <p>Cultivated a mindset that allows you to overcome challenges, eliminating fear, procrastination, and self-doubt, while building self-belief and confidence in your abilities as a business leader.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Enhance Your Sales &amp; Marketing Skills</h4>
            <p>Overcame the fear of sales, became comfortable discussing and offering your products, and learned how to attract and retain premium clients, mastering lead generation and effective pricing strategies.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Leverage Automation to Increase Efficiency</h4>
            <p>Learned how to implement automations into your business processes, reducing stress and workload, improving delivery and client satisfaction, and allowing you to scale quicker and effectively.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Build a Strong Network &amp; Support System</h4>
            <p>Established valuable connections with like-minded entrepreneurs through events, virtual coaching sessions, and a private Telegram group, offering a supportive community for collaboration and learning.</p>
            <hr />
          </section>
        </section>
        <section className="learn--row">
          <TaskAltIcon className="learn--icon" />
          <section className='learn--row-column'>
            <h4>Achieve Work-Life Balance</h4>
            <p>Developed time management skills and implemented systems to manage your business around your lifestyle, ensuring a balance between work and life while increasing income without excessive hours.</p>
            <hr />
          </section>
        </section>
      </section>

      {/* TODO: Section under */}


      <Testimonials />
      <section className="bio" >
        <img src={paul} alt="Paul McFadden" />
        <article>
          <h2>Paul McFadden</h2>
          <p>
            Paul McFadden is a renowned entrepreneur, award-winning educator in wealth building, and a
            leading authority on business growth and wealth creation. Starting his entrepreneurial journey at
            just 19, Paul quickly built a multi-million-pound property portfolio, achieving financial freedom
            and millionaire status by the age of 26. Leveraging his early success, he expanded into multiple
            business ventures, collectively generating an annual eight-figure turnover.
          </p>
          <p>
            With a proven track record of scaling businesses, Paul has become a trusted mentor and coach to
            both aspiring entrepreneurs and seasoned business owners. His insights and strategies have
            empowered thousands to grow their businesses and attain financial independence. Recognised
            globally for his expertise, Paul is a highly sought-after speaker at prestigious business and property
            events.
          </p>
          <p>
            His contributions to wealth education have earned him an honorary membership in Robert
            Kiyosaki’s Wealth Master Panel. In 2022, Paul further cemented his status as a global business
            leader by joining the prestigious ClickFunnels Eight-Figure Club, having generated over
            £10,000,000 in revenue from a single offering.
          </p>
        </article>
      </section>
      {/* <FAQ background={background} /> */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            We Use Cookies
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We use cookies to ensure the best experience on our website. These help us improve our site performance, personalise content, and deliver targeted ads.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            By clicking "Accept All Cookies," you agree to the use of all cookies. You can manage your preferences by selecting "Customise Settings" or view our full Cookie Policy for more information.
          </Typography>
          {showNonEssential ? (
            <Stack>
              <FormGroup>
                <FormControlLabel disabled control={<Switch defaultChecked />} label="Essential Cookies" />
                <FormControlLabel control={<Switch defaultChecked />} label="Performance Cookies" onChange={(e) => handleConsent('performance', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Functionality Cookies" onChange={(e) => handleConsent('ad_personalization', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Analytics Cookies" onChange={(e) => handleConsent('analytics_storage', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Adverstising Cookies" onChange={(e) => handleConsent('ad_storage', e.target.checked)} />
                <FormControlLabel control={<Switch defaultChecked />} label="Social Media Cookies" onChange={(e) => handleConsent('ad_user_data', e.target.checked)} />
              </FormGroup>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
                <Button variant="outlined" color="success" onClick={handleCookies}>Accept Custom</Button>
                <Button variant="outlined" color="success" onClick={handleCookies}>Accept All</Button>
              </Stack>

            </Stack>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '5%' }}>
              <Button variant="outlined" color="success" onClick={handleCookies}>Accept Cookies</Button>
              <Button variant="outlined" color="primary" onClick={handleCustomise}>Customise Settings</Button>
              <Button variant="outlined" color="success" onClick={handleDecline}>Decline Non-Essential</Button>

            </Stack>

          )}

        </Box>
      </Modal>
    </main>
  );
}
